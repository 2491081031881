@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}



/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
	font-family: 'SEGOEUIL';
	src: url(/static/media/SEGOEUIL.373f1faa.eot);
	src: local('SEGOEUIL'), url(/static/media/SEGOEUIL.817fda2e.woff) format('woff'), url(/static/media/SEGOEUIL.5f5b3965.ttf) format('truetype');
}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_1409">Text with this font applied</p> */
.fontsforweb_fontid_1409 {
	font-family: 'SEGOEUIL' !important;
}

/* Font downloaded from FontsForWeb.com */










@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url(/static/media/MYRIADPRO-REGULAR.d30b0546.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url(/static/media/MYRIADPRO-COND.26dcc00c.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url(/static/media/MYRIADPRO-CONDIT.dcf08241.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url(/static/media/MyriadPro-Light.43b72dcd.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url(/static/media/MYRIADPRO-SEMIBOLD.230dfc3b.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url(/static/media/MYRIADPRO-SEMIBOLDIT.6c2fc819.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url(/static/media/MYRIADPRO-BOLDCOND.79d01fda.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url(/static/media/MYRIADPRO-BOLD.1c643226.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url(/static/media/MYRIADPRO-BOLDIT.6cb2210e.woff) format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url(/static/media/MYRIADPRO-BOLDCONDIT.63cfcd40.woff) format('woff');
  }






















  









body {
  font-size: 14px;
  color: #716e6e;
  /* font-family: "Roboto",sans-serif; */
  font-weight: 400;
  line-height: 22px;
  background: #ffff;
  background-repeat: repeat;
  background-position: top;
  background-attachment: fixed!important;
  background-size: cover;
  position: relative;
  min-height: 100%;
  /* padding-bottom: 23px; */
  font-family: 'Myriad Pro Regular';
  /* font-family: 'SEGOEUIL' !important; */
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
  
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.logo img {
  height: 70px;
  width: 470px;
  margin: 0 auto;
  display: inline-block;
}
.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
  display: block;
  max-width: 100%;
  height: auto;
}
.logo {
  padding: 20px 0;
  text-align: center;
}
.outer-wrapper {
  padding: 5% 0px;
  background: #004a7e;
}
.info-sec {
  background: rgba(255, 255, 255, 0.8);
  padding: 25px;
  min-height: 214px;
  border-top: 5px solid rgb(14, 44, 142);
}
.login-head {
  border-bottom: 2px solid #080808;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 14px;
  text-transform: uppercase;
}
h1 {
  
  font-size: 26px;
  font-weight: 400;
  color: #000000;
}
.button:hover, .login-inner input[type=submit]:hover {
  background-color: #0b4e85;
  color: #fff;
  border: 1px solid #0b4e85!important;
}
.button, .login-inner input[type=submit] {
  background-color: #867a69;
  color: #fff;
  
  border: 1px solid #867a69!important;
  transition: ease-in-out .3s;
  -webkit-transition: ease-in-out .3s;
  -moz-transition: ease-in-out .3s;
  padding-left: 0;
  font-style: normal!important;
  font-size: 14px!important;
}
.logo-outer {
  margin-bottom: 50px;
  border-bottom: 5px solid #0b4e85;
}
.navbar.navbar-default.nav-new {
  border: 1px solid #0b4e85;
  margin-top: 12px;
  height: 35px !important;
  min-height: auto;
  background: #0b4e85;
}
.dropdown-submenu {
  position: relative;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.main-section-top h1 {
  font-size: 60px;
  text-align: center;
  text-shadow: 3px 3px 4px #333;
  font-weight: bold;
  margin-bottom: 0px;
  color: #fff;
}
.main-section-top {
  background: rgba(0, 0, 0, 0.65);
  margin-bottom: 15px;
  text-align: center;
  padding: 11px 0px 0px;
  border: 1px solid #919094;
}
.button, .login-inner input {
  width: 100%;
  margin-bottom: 15px;
  height: 35px;
  outline: none;
}
h2, h3, h4 {
  font-weight: 700 !important;
  font-size: 18px !important;
}
.login-inner input {
  padding-left: 10px;
  border: 1px solid #c9cac8;
  transition: ease-in-out .3s;
  -webkit-transition: ease-in-out .3s;
  -moz-transition: ease-in-out .3s;
  font-style: italic;
  font-size: 13px;
  
}
.forgot {
  width: 100%;
  background: #867a69;
  color: #fff;
  text-align: center;
  padding: 10px;
}
a {
  text-decoration: none !important;
}
footer.login-footer {
  text-align: center;
  /* position: absolute; */
  left: 0px;
  right: 0px;
  bottom: 0px;
  position:absolute;
}
.login-footer {
  background: #867a69;
  color: #fff;
  /* padding: 26px 0 30px; */
}
footer.login-footer p {
  margin-bottom: 0px;
  
}
.dropdown-menu {
 border: 0px solid;
}
.col-md-6.col-sm-5 p {
  text-align: left;
}
.col-md-6.col-sm-7 {
  text-align: right;
}
footer.login-footer.fst {
  background: #575858;
}
footer.login-footer.fst {
  margin-bottom: 15px;
}
.dropdown-submenu-container .dropdown-menu {
  margin-left: 0px !important;
  margin-right: auto;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #0b4e85;
}
a.dropdown-item {
  font-size: 14px;
}
.login-head~p {
  line-height: 1.5;
}
.container.footer {
  max-width: 100%;
  padding: 11px;
}
a.primary-btn:hover{
  color: #fff;
  background: #867a69;
  border: 1px solid #867a69;
}
.view-reward:hover {
  color: blue;
}
.view-reward a {
  color: #ffffff;
}

.info-sec p {
  color: #0b4e85;
}
/* label {
  display: inline-block;
  max-width: 100%;

  font-weight: 700;
} */
.info-sec img {
  padding: 0px 34px;
}
.info-sec {
  background: rgba(255, 255, 255, 0.8);
  padding: 25px;
  min-height: 214px;
  border-top: 5px solid #867a69;
}
.modal {
  text-align: center;
  padding: 0!important;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-header {
  background: #867a69;
}
div#myModal h4 {
  color: #fff;
  font-size: 17px;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.login-wrapper {
  height: auto;
}
.login-inner {
  border-top: 5px solid #867a69;
  padding: 28px 30px 38px;
  background: rgba(255, 255, 255, 0.8);
}

.login-inner input[type=email], .login-inner input[type=text] {
  background: url(https://gulfeaglerewardsloyalty.com/images/email.png) no-repeat center right #fff;
  padding-right: 50px;

}
.login-inner input[type=password] {
  background: url(https://gulfeaglerewardsloyalty.com/images/key.png) no-repeat center right #fff;
  padding-right: 50px;
}
.button, .login-inner input {
  width: 100%;
  margin-bottom: 15px;
  height: 35px;
  outline: none;
}
.container {
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;
}
h1.login-head {
  font-size: 26px;
  font-size: 26px;
  font-weight: 400;
  color: #0b4e85;
}
.login-head {
  border-bottom: 2px solid #0b4e85;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 14px;
  text-transform: uppercase;
}
@media (min-width: 1200px)
{
.container {
    width: 1170px !important;
}
}
nav.navbar.navbar-expand-lg.navbar-light.bg {
  border: 1px solid #0b4e85;
  margin-top: 12px;
  height: 35px !important;
  min-height: auto;
  background: #0b4e85;
}
.navbar-light .navbar-nav .nav-link {
    color: #4b4c4c !important;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  background: #ffffff !important;
  color: rgb(255 255 255);
}
div#basic-navbar-nav {
  background: #ffffff !important;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
}
.navbar {
  padding-top: 0rem;
  padding-bottom: 0rem !important;
}
div#basic-navbar-nav {
  padding-left: 15px;
  padding-right: 15px;
}
a.nav-link {
  position: relative;
  padding: 7px 13px !important;
}
.dropdown-menu
{
  background-color: #867a69  !important;
  border-radius: 0px !important;
}
.dropdown-item{
  color: #fff !important ;
}
.dropdown-menu[data-bs-popper] {
  margin-top: 0rem !important;
}
.dropdown-item:hover {
  color: #fff !important;
  background-color: #564f43 !important
}
.main-section {
  background: rgba(0, 0, 0, 0.65);
  margin-bottom: 15px;
  text-align: center;
  padding: 11px;
  border: 1px solid #919094;
}
.login.section {
  padding: 20px;
}
.login.section h2 {
  color: #fff;
  margin-top: 25px;
  font-size: 34px !important;
  font-weight: 500 !important;
  letter-spacing: 15px;
}
.login.section p {
  font-size: 20px;
  color: #fff;
  line-height: 28px;
}
.login.section img {
  margin: 30px auto;
  width: 70%;
}
 
.boder-outer {
  border-width: 0px 0px 0px 1px;
  padding-left: 30px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #0b4e85 , rgba(0, 0, 0, 0)) 1 100%;
}
.points {
  background: #867a69;
  color: #fff;
  padding: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

a.primary-btn {
  background: #0b4e85;
  border: 1px solid #0b4e85;
  color: #fff;
  font-weight: 600;
  display: block;
  text-decoration: none !important;
  padding: 5px;
  margin-bottom: 10px;
}

svg.gift {
  font-size: 76px;
  padding: 16px;
  width: 110px;
  text-decoration: none !important;
  margin: 0 auto;
  display: block;
  height: 110px;
  margin-bottom: 10px;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 50%;
}

.nav-bg div#basic-navbar-nav{
  width: auto;
  height: 29px;
  background: url(https://otb-demo.com/images/nav_bg.gif) !important;
}

.cat-div {
  width: auto;
  padding-top: 25px;
  padding-bottom: 3px;
  background: #0b4e85;
  padding-left: 10px;
}
.page-title {
  width: 960px;
  height: 93px;
  color: #FFF;
  margin: 0 auto;
}
.cat-div {
  color: #fff;
}
form.otbdemo{
  font-size: 10pt;
  margin-bottom: 20px;
  width: auto;
}
.btn_1:hover {
  background-color: #0b4e85;
  border: 1px solid #0b4e85;
  border-right: none;
}
.green-bar {
  background: #867a69;
  width: auto;
  padding-bottom: 5px;
  padding-top: 5px;
}
.pointstext {
   color:#fff;
  font-size: 12px;
  float: left;
  margin-left: 5px;
  margin-top: 0px;
}
.shopingcarttext {
  color: #ffff;
}
.nav-bg {
  height: 93px !important;
  width: 960px !important;
  margin: 0 auto;
}
.inner-page-title {
  width: auto;
  margin: 0 auto;
}
.inner-page-title a.nav-link {
  font-size: 12px;
}
.inner-page-title a.nav-link
{
  padding: 3px 20px !important;
}

.contents {
  width: 960px;
  height: auto;
  margin: 0 auto;
  background: url('https://otb-demo.com/assets/images/bg-white.png')  #949494;
  padding-top: 20px;
  margin-top: 39px;
}
.right-col {
  width: 940px;
  z-index: 999;
  padding: 10px;
}
.inner-page-title a.nav-link
{
  padding: 8px 5px 5px 15px;
  display: block;
  display: block;
  position: relative;
  float: left;
  margin-top: 0px;
  width: 141px;
}
a.home.nav-link {
  width: 90px;
}

strong {
  font-weight: 700;
}

.inner-page-title .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover
{
  background: #ffffff !important;
}
.go-back-div {
  float: right;
}
.cat-heding {
  width: auto;
  border-bottom: 2px solid #0b4e85;
  color: #0b4e85;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.green-div-bg {
  width: 958px;
  height: 70px;
  border: 1px solid #FFF;
  background: #0b4e85;
  margin: 0 auto;
}
.notes {
  list-style: disc outside none;
  list-style: initial;
  color: #F53232;
}
.cate-TextBox {
  margin-bottom: 5px !important;
  width: 225px;
  height: 25px;
  border: 1px solid #0053a0;
  padding: 5px;
  border-radius: 5px;
  outline: none;
}

.pagesnotes{
  margin: 20px;
  display: inline-block;
  width: 890px;
}
a.viewall {
  font-size: 12px;
  color: #195a92;
  text-decoration: underline;
}
.top {
  color: white;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: center;
  padding-top: 15px;
}
.top .topaay{ 
  color: white;
  font-size: 16px;
}

.imgstyle{
  width: 16px;
  height: 16px;
}
.addnewemail{
  font-size: 12px !important;
    color: #195a92 !important;
}

select {
  font-family: arial;
  font-size: 16pt;
  width: 100px;
}
.tinytable th {
  background-color: #0b4e85 !important;
  color: #F7FBFF;
  border-right: 1px solid #fff;
}
.tinytable h3 {
  text-align: center !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.img-act {
  height: 12px;
  width: 12px;
  padding: 0 3px 0 5px;
  vertical-align: text-top;
}
.cat-heding {
  width: auto;
  border-bottom: 2px solid #0b4e85;
  color: #0b4e85;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.cat-heding h2 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #2f526e;
  float: left;
  margin: 0px;
}
#banner {
  margin: 60px 20px 20px 20px;
  padding: 0;
  text-align: left;
}
#banner {
  background-repeat: no-repeat;
  background-position: center center;
}
.ban-table {
  clear: both;
  margin: 0 auto;
}
.preview_bannner {
  width: 918px;
  height: 495px;
  border: 1px solid #0053a0;
  float: left;
  margin-bottom: 10px;
  background-color: #E5E5E5;
}
strong.Upload {
  color: #020202;
}
hr.borderline {
  border: 1px solid #0053a0 !important;
}
.me-auto.colornav.navbar-nav .navbar-light .navbar-nav .nav-link{
  color: white !important;
}
.nav-bg.sub .navbar-light .navbar-nav .nav-link {
  color: rgb(0 0 0) !important;
}
.nav-bg.sub .navbar-light .navbar-nav .nav-link:hover{
  color: white !important;
}

hr {
  border: 1px solid #0053a0 !important;
}
input#query {
  margin: 10px !important;
}
.forgot a {
  color: white;
  text-decoration: none;
}
img.img {
  width: 100%;
}
img.img2 {
  width: 100%;
  /* float: right; */
  /* width: 40%; */
}
.col-md-4 {
  background: white;
}
.box {
  background: #4caf50;
}

.boxes {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}
.headerLogin {
  background: cornsilk;
}
.\36 {
  background: #fff;
}
.\32 {
  background: aqua;
}
img.logo {
  padding: 0px;
  width: 260px;
  float: left;
}
.col-md-6.logodiv {
  background: #ffff;
}
.catalog {
  text-align: center;
  padding-top: 25px;
}
.col-md-2.catalogdiv {
  background: #ffff;
  border-right: 2px solid #d4d4d4;
  height: auto;
}
span.email {
  font-size: 16px;
  color: #004a7e;
  padding-right: 10px;
}
span.phone {
  font-size: 16px;
  margin-left: 5px;
  border-left: 2px solid #bfbdbd;
  color: #004a7e;
  padding-left: 10px;
}
h2.question {
  font-weight: 400 !important;
  padding: 0px;
  margin: 0px;
  line-height: 1;
  font-size: 36px !important;
}
small.address p {
  font-style: italic;
  color: #747474;
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}
img.dimensions {
  width: 60px;
  margin-right: 10px;
}
.col-md-4 {
  padding: 10px;
}
.col-md-10 {
  padding: 0px;
}
.navbar-light .navbar-brand {
  color: rgb(255 254 254 / 90%);
}
a.catalog.nav-link {
  font-size: 16px;
  color: #004a7d !important;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.handph {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link {
    color: #4b4c4c !important;
}
div#basic-navbar-nav {
  background: #f8f9fa;
}
.email span{
  color: #004a7e;
  margin: 0px !important;
  padding: 0px !important;
}
 span .email{
  color: #004a7e;;
}
.phone span {
  margin: 0px !important;
  padding: 0px !important;
}
 p {
  margin: 0px !important;
  padding: 0px !important;
}
a.nav-link:last-child {
  padding-right: 0px !important;
}
.nav-link:last-child{
  position: relative;
}
.nav-link:last-child::before {
  content: '';
  position: absolute;
  top: 19px;
  width: 0px;
  border-left: 1px solid #bfbdbd;
  z-index: 11;
  height: 49px;
  left: 0px;
}
img.centralimages {
  width: 60%;
}
.bkg {
  background: #ffff;
}
.foram {
  width: 40%;
  float: right;
}
input[type=text], select {
  font-size: 16px;
  height: 55px;
  width: 100%;
  padding: 9px 20px;
  margin: 20px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 1px;
  box-sizing: border-box;
}
input[type=submit] {
  font-size: 15px !important;
  width: 24%;
  background-color: #ffae00;
  color: #454545;
  padding: 7px 9px;
  margin: 0px;
  border: none;
  border-radius: 0px;
  margin-right: 0px !important;
}
.input-group.checking {
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
a.btn.btn-link.forgot {
  font-size: 12px;
  padding: 0px;
}
.forgot {
  background: #ffffff;
  color: #394c5a;
  text-align: center;
  padding: 10px;
}
a.btn.btn-link.forgot {
  width: auto;
  height: auto;
  display: inherit;
}
input[type=submit]:hover {
  background-color: #004a7e;
  color: #fff;
}
.foram {
  margin: auto;
  width: 40%;
  padding: 10px;
  display: flex;
  justify-content: center;
  min-height: 290px;
  align-items: flex-end;
}
.info {
display: flex;
align-content: center;
justify-content: space-between;
align-items: center;
}
nav.navbar.navbar-expand-lg.navbar-light {
  padding-top: 40px;
  padding-bottom: 20px;
}
img.googleplay {
    width: 13%;
    padding: 5px;
}
.centraltext {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
a.googleplay {
  margin-left: 5px;
}
a.appstore {
  margin-right: 5px;
}
.input-group.checking {
  margin-top: 15px;
}
label.remember {
  font-size: 12px;
}
.addresssection {
  line-height: 1.2;
}
.footerbotm {
  margin-top: 30px;
  font-size: 14px;
  color: #ffff;
  text-align: center;
  padding-bottom: 10px;
}
input[type="submit"] {
  height: 40px;
  width: 100px;
}

input#password {
  height: 55px;
  width: 100%;
  padding: 9px 20px;
  margin: 5px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 1px;
  box-sizing: border-box;
}
svg.svgicon {
  color: #1c252cad;
  margin-top: 0px;
  position: absolute;
  width: 9px;
  margin-left: 125px;
}
input#vehicle1 {
  vertical-align: baseline;
}
.container.fpage {
  padding-top: 30px;
  float: left;
  padding-bottom: 30px;
}

.underconst{
text-align: center;
}
a.logofpage.navbar-brand {
  color: #2d2d2d !important;
  font-weight: 400 !important;
  font-size: 16px  !important;
}
a.fpagenav.nav-link {
  font-size: 16px;
}
.cart{
  text-align: right;
}
ul.pointsdetail {
  margin-left: auto;
  text-align: right;
  padding-left: 0px;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -8px;
}
ul.pointsdetail li{
  font-weight: 400;
  padding-right: 20px;

  padding-bottom: 5px;
  text-align: left;
  padding-top: 15px;
  font-size: 30px !important;
  color: #4b4c4c;
}

ul.pointsdetail li:last-child{
  padding-right: 0px;
}
span.pointsav {
  font-size: 18px;
  font-weight: 400;
  display: block;
}

.container-fluid.fpage {
  padding: 15px 20px 5px;
}
span.pointsav {
  line-height:1.7;
}
span.logout {
  border-left: 2px solid #716e6e;
  padding-left: 5px;
}
span.shop {
  padding-right: 10px;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-dark {
  padding-top: 0px;

}

nav.navfpage.navbar.navbar-expand-lg.navbar-light {
  background: #e8e8e8;
}
nav.navfpage.navbar.navbar-expand-lg.navbar-light {
  padding-top: 0px !important;
  padding-left: 20px;
  padding-right: 20px;
}
a.logofpage.navbar-brand {
  color: #4b4c4c !important;
  font-weight: 500;
}
.col-md-5.fpage {
  padding-left: 0px;
  padding-right: 0px;
}
.col-md-7.fpage {
  padding-left: 0px;
  padding-right: 0px;
}
a.fpagenav.nav-link::before {
  content: none;
}


a.fpagenav.nav-link {
  background: none !important;
}

.col-md-5.fpage {
  position: relative;
}
a.fpagenav.nav-link {
    font-weight: 400 !important;
}
a.fpagenav.nav-link:hover {
  background-color: #867a69;
color: #fff;
opacity: 1;
}

.inner-page-title .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .fpagenav.nav-link:hover {
  background: #716e6e !important;
  color: #fff !important;
}
a.fpagenav.nav-link:last-child {
  padding: 7px 14px !important;
}


.inner-page-title .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .fpagenav.nav-link:focus {
  background: #716e6e !important;
  color: #fff !important;
}
.centraldata.fpage {
  text-align: center;
  line-height: 2.1;
  font-size: 18px;
  padding-top: 40px;
  max-width: 973px;
  margin: 0 auto;
}
p.fisrtline {
  font-weight: 400 !important;
  font-size:36px !important;
}
button.centralbutton {
  font-weight: 500;
  color: #716e6e;
  background: #e8e8e8;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 7px 80px;
  border: 1px solid #efeded;
  font-size: 28px;
  color: #373737 !important;
}
button.centralbutton:hover {
  background: #716e6e !important;
  color: #ffff !important;
  opacity: 0.6;
  transition: 0.3s;
  
}
.centraldata.fpage p{
  font-size: 18px;
  font-weight: 400;
  color: #4b4c4c !important;
}

.imagebox {
  padding-top: 190px;
  padding-bottom: 80px;
}
.bg.loginpage {
  width: 100%;
  padding: 0px;
  margin: 0px;
  background: #004a7d;
}
img.logo.fpage {
   margin-top: 8px; 
  width: 318px;
}
.col-md-3 img {
  border: 1px solid #e5e5e5b5;
  width: 100%;
}
.imagebox {
  display: flex;
  align-content: center;
  justify-content: center;
}
.col-md-3.fpage {
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}
h2.pro {
  color: #4b4c4c !important;
  font-size: 24px !important;
  margin-bottom: 0px;
  background: #e8e8e8;
  padding: 12px 0px;
  font-weight: 400 !important;
}
p.pimage.fpage {
  color: #4b4c4c !important;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
}

a.fpagenav.nav-link::before {
  position: absolute;
  top:8px;
    content: '';
    height: 55%;
    border-right:1px solid #4b4c4c !important;
    left: 0px;
    z-index: 111;
}

span.logout a {
  text-decoration: none;
  color: #2e2e2e;
}

span.shop a {
  text-decoration: none;
  color: #2e2e2e;
}
a.fpagenav.first.nav-link::before{
  display: none;
}
span.pointsav {
  color: #4b4c4c !important;
}
a.catalog.nav-link {
  padding-right: 32px !important;
}
label.remember {
  color: #394c5a;
  font-size: 13px;
}
a.btn.btn-link.forgot {
  font-size: 13px;
}
.container-fluid.poh {
 
  font-size: 18px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
.container-fluid.poh h1{
  padding-bottom: 0px !important;
  margin-bottom: 0px;
  line-height: 1.2;
  font-size: 36px !important;
}
.pointsdetails {
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: 1381px;
  margin: 0 auto;
  width: 100%;
}

thead.poh {
  background: #8d8d8d;
  font-size: 18px;
  font-weight: bold !important;
}

p.tableline.poh {
  text-align: right;
}
td {
  font-size: 18px !important;
}
td:nth-child(2){
  color: #ff0000;
  vertical-align: middle;
}
td:nth-child(3){

  vertical-align: middle;
}
td:nth-child(1){

  vertical-align: middle;
}

.col-md-10.poh {
  text-align: right;
}
span.threemonth.poh {
  color: #716e6e;
  position: relative;
  padding-right: 15px;
  padding-left: 10px;
}
span.sixmonth.poh {
  color: #716e6e;
  position: relative;
  padding-right: 15px;
  padding-left: 10px;
}
span.tewlvemonth.poh {
  color: #716e6e;
  position: relative;
  padding-left: 10px;
  padding-right: 0px;
}
.col-md-6.poh {
  padding: 0px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

span.threemonth.poh::after {
  content: '';
  position: absolute;
  border-right: 1.5px solid #8d8d8d;
  width: 5px;
  top: 3px;
  height: 19px;
  right: 4px;
}
span.sixmonth.poh::after {
  content: '';
  position: absolute;
  border-right: 1.5px solid #8d8d8d;
  width: 5px;
  top: 3px;
  height: 19px;
  right: 4px;
}

.col-md-10.poh {
  padding: 0px;
  margin: 0px;
}
.col-md-10.poh a:last-child{
margin-right: 0px;
padding-right: 0px !important;
}
th:first-child {
  width: 50%;
}
th:nth-child(2) {
  width: 30%;
}
.col-md-2.poh {
  text-align: right;
}
.col-md-2.poh svg{
  color: #6b6868;
  width: 22px;
  
}
.col-md-2.poh a{
  margin-left: 7px;
}
h2.question {
  cursor: default;
}
.email:hover {
  cursor: pointer !important;
  font-size: 16px;
  color: #004a7e;
  text-decoration: underline;
}
span.phone:hover{
  cursor: pointer !important;
  font-size: 16px;
  color: #004a7e;
  text-decoration: underline;

}
small.address p:hover {
  cursor: default;
}
[role=button] {
  cursor: default;
}
a.catalog.nav-link:hover {
  color: #004a7d !important;
  text-decoration: underline !important;
}
:focus-visible {
  outline: none;

}
::-webkit-input-placeholder {
  font-size: 14px;
  color: #141515;
}
:-ms-input-placeholder {
  font-size: 14px;
  color: #141515;
}
::placeholder {
  font-size: 14px;
  color: #141515;
}
.table>:not(:first-child) {
  border-top: 0px solid currentColor;
}
th {
  padding: 20px !important;
}
td {
  padding-left: 20px !important;
}
p.tableline.poh {
  font-size: 12px;
}

svg.doc.poh {
  height: 25px;
  width: 25px;
}
svg.print.poh {
  height: 25px;
  width: 25px;
}
p.tabledata {
  font-size: 12px;
}
span.refund {
  color: #08a100;
}
a.distr.nav-link:last-child {
  padding-left: 24px !important;
}
thead.poh th {
  padding: 15px 20px !important;
}

.ant-picker{
  border: 0 !important;
  padding-left: 0 !important;
}
.date.poh {
  padding: 0 0 5px;
}
.pointsdetails .table tbody tr td {
  padding: 0.35rem 0.5rem;
}
.pointsdetails table.table {
  margin-bottom: 7px;
}

.pointsdetails .table tbody tr:nth-child(odd){
  background: #e8e8e8;
}
@media only screen and (min-width:1800px) and (max-width: 5000px)  {

  .container{
    max-width: 1667px !important;
    width: 100% !important;
  }
  .foram {
    min-height: 370px;
}
.boxes {
  padding-top: 45px;
}
}

